import React from "react"
import ShopifyLogo from "-!svg-react-loader?props[]=className:shopify!../images/svg/shopify.svg";
import ExternalLink from "-!svg-react-loader?props[]=className:shopify!../images/svg/external-link.svg";
import BabybeauHome from "../images/portfolio/babybeau-home.png";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Babybeau = () => (
  <Layout>
    <SEO title="Project | Baby Beau &amp; Belle" />
    <div className="project">
      <div className="heading">
        <div class="title">
          <h1>Baby Beau &amp; Belle</h1>
          <div class="icons">
            <ShopifyLogo alt="Shopify" />
          </div>
        </div>
        <div class="site-link">
          <a href="https://babybeauandbelle.com/" target="_blank"><span>Website</span> <ExternalLink /></a>
        </div>
      </div>

      <div class="description">
        <p>Baby Beau &amp; Belle has specialized in in creating one-of-a-kind designs for babies and toddlers for over 30 years. Shop our handmade baby and newborn clothes.</p>
      </div>

      <div class="collaborations">
        <p>
          <span>In collaboration with</span>
          <div class="links">
            <a href="https://www.shoppetheory.com/" target="_blank">Shoppe Theory</a>
            <a href="https://aeolidia.com/" target="_blank">Aeolidia</a>
          </div>
       </p>
      </div>

      <div class="project-body">
        <img src={BabybeauHome} />
     </div>
    </div>
  </Layout>
)

export default Babybeau